import { Text } from '@medsimples/design-system/src/chakra';
import schemes from '../../styles/colorSchemes/medsimples';

export default function MedSimples() {
  return (
    <>
      <Text as='span' fontWeight='700' color={schemes.colors.blue[500]}>
        Med
      </Text>
      <Text as='span' fontWeight='700' color={schemes.colors.cyan[500]}>
        Simples
      </Text>
    </>
  );
}
